// Css
import './shipping_list.scss';
import '../../components/loader/loader.scss';
// External Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import PaginationContainer from '../../components/pagination/pagination_container';
import { Helmet } from 'react-helmet';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Internal Components
import SortHeader from '../../components/layout/sort_header';
import Modal from '../../components/modal/modal';
import SessionExpire from '../../common/session_expire';
import LabelsContent from './labels_content';
import SecurityClearance from '../../components/security/security_clearance';
import ContextMenu from '../../components/context_menu/context_menu';
import NewTabLink from '../../components/context_menu/new_tab_link';

// Internal Functions
import { setTokenHeader, convertDate, removeCaseIdInitialNumber, removeIreqIdInitialNumber } from '../../common/functions';
import { getShippingAddress } from '../../common/helpers';
import { getBusinessSearchValueFromRoute, getBusinessFilterValueFromRoute, getBusinessColumnValueFromRoute } from '../../common/route';
import { getTargetShipDateDefaultSort } from '../../common/helpers';
import {
  onChangeSearch,
  onApplySearchShipping,
  getCasesFromRow,
  getCasesFromSearch,
  getShippingCasesFromFilter,
  orderCasesByDefault,
} from '../../common/search';
import { tableRowLink } from '../../common/table';
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';

// Redux Reducers
import {
  getAllCases,
  getStatuses,
  getMarkShippedCases,
  getMarkShippedModal,
  getSelectedShipDate,
  getShipDateError,
  getError,
  getMarkShippedPending,
  getBatchMarkShippedError,
  getBatchAllFailedMarkShippedError,
  getBatchLabelsModal,
  getBatchLabelsFile,
  getRefresh,
  getOnlyNonAutomaticCasesSucceeded,
} from '../../redux/reducers/bpp/shipping/shipping';

// Redux Actions
import {
  openBatchShippingMarkShippedModal,
  closeMarkShippedModal,
  onBatchShippingCaseSelect,
  onBatchShippingCaseSelectAll,
  onBatchMarkShippedButtonClick,
  closeBatchLabelsModal,
  openBatchLabelsModal,
  clearMarkShippedCases,
} from '../../redux/actions/bpp/shipping/shipping';
import CaseListSidebar from '../case_list/case_list_sidebar';
import CaseListFilter from '../case_list/case_list_filter';

/**
 * Displays the list for cases/item requests that are in the Approve For Shipping and Mark Shipped stages
 * @alias ShippingContent
 * @component
 * @category BPP
 * @subcategory Shipping List
 */
class ShippingContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      filter: '',
      cases_raw: [],
      cases_view: [],
      cases_length: 1,
      cases_sort_method: 'target_ship_date',
      cases_sort_order_ascending: {
        case_id: true,
        shipping_address: true,
        doctor_name: true,
        target_ship_date: true,
        type: true,
        shipping_price: true,
      },
      cases_row_size: 25,
      cases_active_page: 1,
      cases_page_range: 5,
      is_bottom: false,
      column_filter: '',
      side_collapse: false,
      newTabUrl: '',
      selected_label: '',
    };

    this.onChangeSearchPatient = this.onChangeSearchPatient.bind(this);
    this.onPageChange = this.onPageChange.bind(this);

    this.onPatientClick = this.onPatientClick.bind(this);
    this.onSearchButtonClick = this.onSearchButtonClick.bind(this);
    this.onSideBarCollapseClick = this.onSideBarCollapseClick.bind(this);
    this.getStartRange = this.getStartRange.bind(this);
    this.getEndRange = this.getEndRange.bind(this);
    this.onSortClick = this.onSortClick.bind(this);
    this.shippingTable = React.createRef();
  }

  componentDidMount() {
    setTokenHeader();
    const that = this;
    const search = getBusinessSearchValueFromRoute();
    const column = getBusinessColumnValueFromRoute();
    const filter = getBusinessFilterValueFromRoute();

    let cases_raw = this.props.all_cases;

    const cases_sort_method = 'target_ship_date';
    const cases_view = getCasesFromSearch(getShippingCasesFromFilter(cases_raw, filter), search, column);

    that.setState({
      loading: false,
      search: search,
      column_filter: column,
      cases_raw: cases_raw,
      cases_view: that.setOrderByMethod(
        getCasesFromRow(orderCasesByDefault(that, cases_view), that.state.cases_active_page, that.state.cases_row_size),
        cases_sort_method,
        that.state.cases_sort_order_ascending[cases_sort_method]
      ),
      cases_length: cases_view.length,
      cases_sort_method: cases_sort_method,
      filter: filter,
    });
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  componentWillUnmount() {
    this.props.clearMarkShippedCases();
  }

  componentDidUpdate(prevProps) {
    window.$('[data-toggle="tooltip"]').tooltip('fixTitle');

    const that = this;
    const state_filter = this.state.filter;

    const search = getBusinessSearchValueFromRoute();
    const filter = getBusinessFilterValueFromRoute();
    const column = getBusinessColumnValueFromRoute();
    const refresh = this.state.refresh;

    const active_page = 1;
    const row_size = this.state.cases_row_size;
    let cases_view = [];

    if (state_filter !== filter || refresh || this.props.all_cases !== prevProps.all_cases) {
      cases_view = getCasesFromSearch(getShippingCasesFromFilter(that.props.all_cases, filter), search, column);

      const cases_sort_method = 'target_ship_date';
      that.setState({
        cases_view: getCasesFromRow(
          that.setOrderByMethod(cases_view, cases_sort_method, that.state.cases_sort_order_ascending[cases_sort_method]),
          active_page,
          row_size
        ),
        cases_active_page: active_page,
        cases_length: cases_view.length,
        cases_sort_method: cases_sort_method,
        search: search,
        filter: filter,
        column_filter: column,
        refresh: false,
      });

      if (that.props.shipping_list_refresh) {
        that.props.setRefreshToFalse();
      }
    }
  }

  /**
   * Handles page change event and updates the cases view
   * @function
   * @param active_page {number} - The number of the current page
   */
  onPageChange(active_page) {
    //Allow for page changes without when filters are not applied yet
    const column = getBusinessColumnValueFromRoute() ? this.state.column_filter : '';
    const filter = getBusinessFilterValueFromRoute();
    const cases_sort_method = this.state.cases_sort_method;

    let cases_view = getCasesFromSearch(getShippingCasesFromFilter(this.props.all_cases, filter), this.state.search, column);

    this.setState({
      cases_active_page: active_page,
      cases_view: getCasesFromRow(
        this.setOrderByMethod(cases_view, cases_sort_method, this.state.cases_sort_order_ascending[cases_sort_method]),
        active_page,
        this.state.cases_row_size
      ),
    });
  }

  /**
   * Handles when user clicks on a case/item request from the list
   * @function
   * @param event {object} - The event object
   */
  onPatientClick = (event) => {
    const pathname = event.currentTarget.dataset.id.includes('-IR')
      ? `/business/portal/case/${event.currentTarget.dataset.caseid}/item`
      : `/business/portal/case/${event.currentTarget.dataset.caseid}`;
    tableRowLink(event, pathname, this.props);
  };

  /**
   * Retrieves how the column should be sorted
   * @function
   * @param sort {String} - The column type
   * @return {String} ascending or descending
   */
  getSortValue(sort) {
    return sort ? 'asc' : 'desc';
  }

  /**
   * Gets the start of the page numbers to display
   * @function
   * @return {number} - The number of the page
   */
  getStartRange() {
    return this.state.cases_active_page * this.state.cases_row_size - this.state.cases_row_size + 1;
  }

  /**
   * Gets the end of the page numbers to display
   * @function
   * @return {Number} - The number of the page
   */
  getEndRange() {
    return this.state.cases_active_page * this.state.cases_row_size > this.state.cases_length
      ? this.state.cases_length
      : this.state.cases_active_page * this.state.cases_row_size;
  }

  /**
   * Sets the URL route whenever there is a search or filter change
   * @function
   * @param uri_text {String} - The search or filter value
   * @param route_type {String} - 'Search' or 'filter' (whichever one the user is trying to change)
   * @return {String} - The new route
   */
  setRoute = (uri_text, route_type) => {
    const route_base = this.props.history.location.pathname;

    let search = route_type === 'search' ? uri_text : getBusinessSearchValueFromRoute();
    let filter = route_type === 'filter' ? uri_text : getBusinessFilterValueFromRoute();
    let col = route_type === 'col' ? uri_text : getBusinessColumnValueFromRoute();

    search = search !== '' ? `search=${search}` : '';
    filter = filter !== '' ? `filter=${filter}` : '';
    col = col !== '' ? `col=${col}` : '';

    let param = [];

    if (route_type === 'filter') {
      param.push(filter);
    } else {
      if (filter) {
        param.push(filter);
      }
      if (search) {
        param.push(search);
      }

      if (col) {
        param.push(col);
      }
    }

    let route_end = param.length > 0 ? '?' + param.join('&') : '';

    return `${route_base}${route_end}`;
  };

  /**
   * Handles change event when using the search bar
   * @function
   * @param event {Object} - Event object
   */
  onChangeSearchPatient = (event) => {
    onChangeSearch(this, event);
  };

  /**
   * Handles change event when the enter key is pressed after using the search bar
   * @function
   * @param event {Object} - Event object
   */
  keyPress = (event) => {
    if (event.key === 'Enter') {
      this.applySearch();
    }
  };

  /**
   * Applies search from search bar
   * @function
   */
  applySearch() {
    onApplySearchShipping(this);
  }

  /**
   * Handles event when search button is clicked
   * @function
   * @param event {Object} - Event object
   */
  onSearchButtonClick = (event) => {
    this.applySearch();
  };

  /**
   * Handles event when user collapses sidebar
   * @function
   */
  onSideBarCollapseClick() {
    this.setState({
      side_collapse: !this.state.side_collapse,
    });
  }

  /**
   * When user clicks on a filter
   * @function
   * @param filter {String} - The filter's key
   */
  onClickSubMenu = (filter) => {
    this.props.history.replace(this.setRoute(filter, 'filter'));
    this.onPageChange(1);
  };

  /**
   * Orders cases by type
   * @function
   * @param cases {Array} - List of cases to sort
   * @param sort {String} - Ascending or descending
   * @return {Array} - Sorted array of cases
   */
  orderCasesByType(cases, sort) {
    return _.orderBy(cases, ['type'], [sort]);
  }

  /**
   * Orders cases by case id
   * @function
   * @param cases {Array} - List of cases to sort
   * @param sort {String} - Ascending or descending
   * @return {Array} - Sorted array of cases
   */
  orderCasesByCaseId(cases, sort) {
    return _.orderBy(cases, ['case_id'], [sort]);
  }

  /**
   * Orders cases by doctor's name
   * @function
   * @param cases {Array} - List of cases to sort
   * @param sort {String} - Ascending or descending
   * @return {Array} - Sorted array of cases
   */
  orderCasesByDoctorName(cases, sort) {
    return _.orderBy(cases, ['doctor.first_name', 'shipping_address'], [sort, sort]);
  }

  /**
   * Orders cases by shipping address
   * @function
   * @param cases {Array} - List of cases to sort
   * @param sort {String} - Ascending or descending
   * @return {Array} - Sorted array of cases
   */
  orderCasesByShippingAddress(cases, sort) {
    return _.orderBy(cases, ['shipping_address', 'target_ship_date'], [sort, getTargetShipDateDefaultSort()]);
  }

  /**
   * Orders cases by target ship date
   * @function
   * @param cases {Array} - List of cases to sort
   * @param sort {String} - Ascending or descending
   * @return {Array} - Sorted array of cases
   */
  orderCasesByTargetShipDate(cases, sort) {
    return _.orderBy(cases, ['target_ship_date', 'status_priority'], [sort]);
  }

  /**
   * Orders cases by shipping price
   * @function
   * @param cases {Array} - List of cases to sort
   * @param sort {String} - Ascending or descending
   * @return {Array} - Sorted array of cases
   */
  ordercasesByShippingPrice(cases, sort) {
    return _.orderBy(cases, ['shipping_price', 'target_ship_date'], [sort, getTargetShipDateDefaultSort()]);
  }

  /**
   * Refresh route
   * @function
   * @param event {Object} - Event object
   */
  refreshRoute = (event) => {
    if (!this.state.side_collapse) {
      this.props.history.replace(this.props.history.location.pathname);

      this.setState({
        refresh: true,
      });
    }
  };

  /**
   * Determines if case is selected on the mark shipped page
   * @function
   * @param id {String} - The id of the case or item request
   * @return {Boolean} - True or false
   */
  isCaseSelected = (id) => {
    for (const cases of this.props.mark_shipped_cases) {
      if (cases.id === id) {
        return true;
      }
    }
    return false;
  };

  /**
   * Determines if manual shipping fields are filled out for all cases and item requests in the mark shipped modal
   * @function
   * @return {Boolean} True if completed, False otherwise
   */
  isManualShippingCompleted() {
    for (const cases of this.props.mark_shipped_cases) {
      if (cases.shipping_method === 'manual_shipping' && (!cases.tracking_number || !cases.shipping_service)) {
        return false;
      }
    }
    return true;
  }

  /**
   * Determines if all cases are already in mark shipped progress
   * @function
   * @return {Boolean} True if all are in progress, False otherwise
   */
  allCasesMarkShippedInProgress() {
    for (const cases of this.state.cases_view) {
      if (!cases.mark_shipped_in_progress) {
        return false;
      }
    }
    return true;
  }

  /**
   * Right click handler opens context menu
   * @function
   */
  onShippingRightClick = (event) => {
    const case_id = event.currentTarget.dataset.caseid;
    const isIR = case_id.includes('-IR');
    const irUrl = `/business/portal/case/${case_id.substring(0, case_id.length - 4)}/item`;
    const caseUrl = `/business/portal/case/${case_id}`;
    this.setState({
      newTabUrl: isIR ? irUrl : caseUrl,
    });
  };

  /**
   * Creates the list of filters configurations
   * @function
   * @returns {Array} The result menu filters
   */
   buildSidebarFilters() {
    const { statuses } = this.props;
    const { filter } = this.state;
    return [
      {
        filter: 'approve_for_shipping',
        text: 'Approve For Shipping',
        count: statuses['approve_for_shipping'],
        active: filter === 'approve_for_shipping',
        onClick: () => this.onClickSubMenu('approve_for_shipping'),
      },
      {
        filter: 'mark_shipped',
        text: 'Mark Shipped',
        count: statuses['mark_shipped'],
        active: filter === 'mark_shipped',
        onClick: () => this.onClickSubMenu('mark_shipped'),
      },
      {
        filter: 'shipping_labels',
        text: 'View Shipping Label PDFS',
        active: filter === 'shipping_labels',
        onClick: () => this.onClickSubMenu('shipping_labels'),
      },
    ];
  }
  /**
   * Grabs the ID from the constructed path name
   * @function
   * @param {String} - A path were the file lives
   * @returns {String} Return the ID
   */
  getShippingIDFromPath = (path) => {
    const file_name = path.split('_')
    return file_name[file_name.length - 1].split('.')[0]
  }

  render() {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return (
            <div className="">
              <Helmet>
                <title>Case List | InBrace Business Portal</title>
              </Helmet>
              <SecurityClearance
                mode="ANY"
                permission_list={['CASE_SHIPPING_APPROVAL', 'IREQ_SHIPPING_APPROVAL', 'CASE_SHIPPING_RELEASE', 'IREQ_SHIPPING_RELEASE']}
              >
                <CaseListSidebar side_collapse={this.state.side_collapse} onClickCollapse={this.onSideBarCollapseClick}>
                  {/* eslint-disable-next-line */}
                  <a className="submenu-title" onClick={this.refreshRoute}>
                    Shipping
                  </a>
                  <CaseListFilter filters={this.buildSidebarFilters()} />
                </CaseListSidebar>
                {this.state.filter === 'shipping_labels' ? (
                  <LabelsContent side_collapse={this.state.side_collapse} />
                ) : (
                  <div className={this.state.side_collapse ? 'sidenav-submenu-offset submenu-collapse' : 'sidenav-submenu-offset'}>
                    <div className="main-content">
                      <div className="dark-options">
                        <div className="page-heading">
                          {!this.state.filter || this.state.filter === 'approve_for_shipping' ? 'Approve For Shipping' : 'Mark Shipped'}{' '}
                        </div>
                        <div className="dark-search">
                          <input
                            type="text"
                            className="form-control search-bar-dark font-awesome"
                            placeholder="Search"
                            aria-describedby="basic-addon1"
                            onChange={this.onChangeSearchPatient}
                            onKeyPress={this.keyPress}
                            value={this.state.search}
                          />
                        </div>

                        <button className="btn btn-light-3" onClick={this.onSearchButtonClick}>
                          <i className="fa fa-search" aria-hidden="true" />
                        </button>
                        {this.state.filter === 'mark_shipped' &&
                          (userHasPermission('CASE_SHIPPING_RELEASE', user_roles_and_permissions.permissions) ||
                            userHasPermission('IREQ_SHIPPING_RELEASE', user_roles_and_permissions.permissions)) && (
                            <button
                              className="btn btn-light btn-batch-mark-shipped"
                              onClick={() => this.props.openBatchShippingMarkShippedModal(this.props.mark_shipped_cases)}
                              disabled={this.props.mark_shipped_cases.length === 0}
                            >
                              Mark Shipped
                            </button>
                          )}
                      </div>
                      {this.state.cases_view.length > 0 ? (
                        <div className="table-case-view">
                          <table className="table table-condensed table-striped table-industry table-shipping">
                            <thead className="table-inbrace-dark-industry">
                              <tr>
                                {this.state.filter === 'mark_shipped' &&
                                (userHasPermission('CASE_SHIPPING_RELEASE', user_roles_and_permissions.permissions) ||
                                  userHasPermission('IREQ_SHIPPING_RELEASE', user_roles_and_permissions.permissions)) ? (
                                  <th className="mark-shipped-width">
                                    <input
                                      type="checkbox"
                                      className="pointer"
                                      onClick={(e) => {
                                        this.props.onBatchShippingCaseSelectAll(e, this.state.cases_view, user_roles_and_permissions);
                                      }}
                                      disabled={this.allCasesMarkShippedInProgress()}
                                    />
                                  </th>
                                ) : null}
                                <th className="shipping-type-width" data-method="type" onClick={this.onSortClick}>
                                  <SortHeader
                                    title="Type"
                                    category="type"
                                    sort_method={this.state.cases_sort_method}
                                    ascending={this.state.cases_sort_order_ascending['type']}
                                  />
                                </th>
                                <th className="shipping-caseid-width" data-method="case_id" onClick={this.onSortClick}>
                                  <SortHeader
                                    title="Case ID"
                                    category="case_id"
                                    sort_method={this.state.cases_sort_method}
                                    ascending={this.state.cases_sort_order_ascending['case_id']}
                                  />
                                </th>
                                <th className="shipping-name-width" data-method="doctor_name" onClick={this.onSortClick}>
                                  <SortHeader
                                    title="Doctor"
                                    category="doctor_name"
                                    sort_method={this.state.cases_sort_method}
                                    ascending={this.state.cases_sort_order_ascending['doctor_name']}
                                  />
                                </th>
                                <th className="shipping-address-width" data-method="shipping_address" onClick={this.onSortClick}>
                                  <SortHeader
                                    title="Shipping Address"
                                    category="shipping_address"
                                    sort_method={this.state.cases_sort_method}
                                    ascending={this.state.cases_sort_order_ascending['shipping_address']}
                                  />
                                </th>
                                {this.state.filter === 'mark_shipped' ? (
                                  <th className="shipping-price-width" data-method="shipping_price" onClick={this.onSortClick}>
                                    <SortHeader
                                      title="Shipping Price"
                                      category="shipping_price"
                                      sort_method={this.state.cases_sort_method}
                                      ascending={this.state.cases_sort_order_ascending['shipping_price']}
                                    />
                                  </th>
                                ) : null}
                                <th className="shipping-target-ship-date-width" data-method="target_ship_date" onClick={this.onSortClick}>
                                  <SortHeader
                                    title="Target Ship Date"
                                    category="target_ship_date"
                                    sort_method={this.state.cases_sort_method}
                                    ascending={this.state.cases_sort_order_ascending['target_ship_date']}
                                  />
                                </th>
                              </tr>
                            </thead>
                            <tbody ref={this.shippingTable} className="table-inbrace-dark-industry context-menu-container">
                              <ContextMenu container={this.shippingTable} containerType="table">
                                <NewTabLink url={this.state.newTabUrl} />
                              </ContextMenu>
                              {this.state.cases_view.map((cases, index) => {
                                return (
                                  <tr className="pointer" onContextMenu={this.onShippingRightClick} key={index} data-caseid={cases.id}>
                                    {this.state.filter === 'mark_shipped' &&
                                    (userHasPermission('CASE_SHIPPING_RELEASE', user_roles_and_permissions.permissions) ||
                                      userHasPermission('IREQ_SHIPPING_RELEASE', user_roles_and_permissions.permissions)) ? (
                                      <td className="mark-shipped-width td-industry-offset">
                                        <input
                                          type="checkbox"
                                          className="pointer"
                                          checked={this.isCaseSelected(cases.id)}
                                          onChange={() => this.props.onBatchShippingCaseSelect(cases)}
                                          disabled={
                                            cases.mark_shipped_in_progress ||
                                            (cases.id.includes('-IR') && !userHasPermission('IREQ_SHIPPING_RELEASE', user_roles_and_permissions.permissions)) ||
                                            (!cases.id.includes('-IR') && !userHasPermission('CASE_SHIPPING_RELEASE', user_roles_and_permissions.permissions))
                                          }
                                        />
                                      </td>
                                    ) : null}
                                    <td
                                      className={
                                        'shipping-type-width' +
                                        (!this.state.filter ||
                                        this.state.filter === 'approve_for_shipping' ||
                                        (!userHasPermission('CASE_SHIPPING_RELEASE', user_roles_and_permissions.permissions) &&
                                          !userHasPermission('IREQ_SHIPPING_RELEASE', user_roles_and_permissions.permissions))
                                          ? ' td-industry-offset'
                                          : '')
                                      }
                                      onMouseUp={this.onPatientClick}
                                      data-id={cases.id}
                                      data-caseid={cases.case_id}
                                      data-requestid={cases.type === 'Item Request' ? cases.id : null}
                                    >
                                      {cases.type}
                                    </td>
                                    <td
                                      onMouseUp={this.onPatientClick}
                                      data-id={cases.id}
                                      data-caseid={cases.case_id}
                                      data-requestid={cases.id.includes('-IR') ? cases.id : null}
                                    >
                                      {cases.gen_2 && <span className="case-tag case-gen2">Gen 2.0</span>}
                                      {cases.id.includes('-IR') ? removeIreqIdInitialNumber(cases.id) : removeCaseIdInitialNumber(cases.id)}
                                      {cases.late && (
                                        <i
                                          className="fa fa-flag target-date-flag"
                                          aria-hidden="true"
                                          data-toggle="tooltip"
                                          data-placement="right"
                                          title={
                                            cases.id.includes('-IR')
                                              ? `Missed Target Ship Date: ${convertDate(cases.late)}`
                                              : `Missed ${cases.late.type} Date: ${convertDate(cases.late.date)}`
                                          }
                                        />
                                      )}
                                    </td>
                                    <td
                                      onMouseUp={this.onPatientClick}
                                      data-id={cases.id}
                                      data-caseid={cases.case_id}
                                      data-requestid={cases.id.includes('-IR') ? cases.id : null}
                                    >
                                      {cases.doctor.first_name} {cases.doctor.last_name}
                                    </td>
                                    <td
                                      onMouseUp={this.onPatientClick}
                                      data-id={cases.id}
                                      data-caseid={cases.case_id}
                                      data-requestid={cases.id.includes('-IR') ? cases.id : null}
                                    >
                                      {this.state.filter === 'mark_shipped' ? getShippingAddress(cases.delivered_address) : cases.shipping_address}
                                    </td>
                                    {this.state.filter === 'mark_shipped' ? (
                                      <td
                                        onMouseUp={this.onPatientClick}
                                        data-id={cases.id}
                                        data-caseid={cases.case_id}
                                        data-requestid={cases.id.includes('-IR') ? cases.id : null}
                                      >
                                        ${cases.shipping_price}
                                      </td>
                                    ) : null}
                                    <td
                                      onMouseUp={this.onPatientClick}
                                      data-id={cases.id}
                                      data-caseid={cases.case_id}
                                      data-requestid={cases.id.includes('-IR') ? cases.id : null}
                                    >
                                      {convertDate(cases.target_ship_date)}
                                      {cases.expedite_ind ? (
                                        <span className="expedited-icon">
                                          <FontAwesomeIcon icon={['fas', 'shipping-fast']} />
                                        </span>
                                      ) : null}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>

                          <PaginationContainer
                            theme="bpp"
                            isBottom={this.state.is_bottom}
                            activePage={this.state.cases_active_page}
                            itemsCountPerPage={this.state.cases_row_size}
                            totalItemsCount={this.state.cases_length}
                            pageRangeDisplayed={this.state.cases_page_range}
                            onChange={this.onPageChange}
                            startRange={this.getStartRange()}
                            endRange={this.getEndRange()}
                            type="Cases"
                          />
                        </div>
                      ) : (
                        <div className="result-none">
                          <div>
                            Sorry, we couldn't find any cases in our system that match your search for "
                            <span className="bold-text">{getBusinessSearchValueFromRoute()}</span>".
                          </div>
                          <br />
                          <div>
                            Suggestions:
                            <ul className="dash-list">
                              <li>Check your spelling and try again</li>
                              <li>Try different keywords</li>
                              <li>Try different filters</li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {this.props.mark_shipped_modal ? (
                  <Modal
                    preset="mark-shipped"
                    modal_size_class="modal-lg"
                    modal_body_class="overflow-show center-text"
                    header_text="Mark Shipped"
                    confirm_btn={
                      !this.props.mark_shipped_error && !this.props.batch_all_failed_mark_shipped_error && !this.props.only_non_automatic_cases_succeeded
                    }
                    confirm_btn_text={this.props.batch_mark_shipped_error ? 'Continue' : 'Ship'}
                    close_btn={!this.props.batch_mark_shipped_error || this.props.only_non_automatic_cases_succeeded}
                    close_btn_text={this.props.batch_all_failed_mark_shipped_error || this.props.only_non_automatic_cases_succeeded ? 'Close' : 'Cancel'}
                    onCloseButtonClick={() => this.props.closeBatchShippingMarkShippedModal('batch')}
                    onConfirmButtonClick={
                      this.props.batch_mark_shipped_error
                        ? this.props.openBatchLabelsModal
                        : () => this.props.onBatchMarkShippedButtonClick(this.props.reload_information)
                    }
                    theme="bpp"
                    in_progress={this.props.mark_shipped_pending}
                    in_progress_text="Marking as Shipped..."
                    ship_date_error={this.props.ship_date_error}
                    datearea_warning_text="Please enter a new ship date, the specified cases failed to meet requirements"
                    confirm_btn_disabled={!this.isManualShippingCompleted()}
                  />
                ) : null}
                {this.props.batch_labels_modal ? (
                  <Modal
                    preset="pdf_viewer"
                    header_text="Shipping Label"
                    modal_size_class="modal-lg modal-lg-pdf"
                    modal_body_class="modal-pdf"
                    onCloseButtonClick={this.props.closeBatchLabelsModal}
                    theme="bpp"
                    pdf_url={this.props.batch_labels_file}
                    original_filename={`${this.getShippingIDFromPath(this.props.batch_labels_file)}_Label_Shipping.pdf`}
                  />
                ) : null}
                {this.props.refresh ? <SessionExpire /> : null}
              </SecurityClearance>{' '}
            </div>
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  }

  onSortClick = (event) => {
    const sort_method = event.currentTarget.dataset.method;
    const column = getBusinessColumnValueFromRoute() ? this.state.column_filter : '';
    const filter = getBusinessFilterValueFromRoute() ? this.state.filter : '';

    let cases_sort_order_ascending = this.state.cases_sort_order_ascending;

    if (sort_method === this.state.cases_sort_method) {
      //Flip order
      cases_sort_order_ascending[sort_method] = !cases_sort_order_ascending[sort_method];
    }

    let cases_view = getCasesFromSearch(getShippingCasesFromFilter(this.props.all_cases, filter), this.state.search, column);

    cases_view = getCasesFromRow(
      this.setOrderByMethod(cases_view, sort_method, cases_sort_order_ascending[sort_method]),
      this.state.cases_active_page,
      this.state.cases_row_size
    );

    this.setState({
      cases_view: cases_view,
      cases_sort_method: sort_method,
      cases_sort_order_ascending: cases_sort_order_ascending,
    });
  };

  setOrderByMethod(cases_view, sort_method, sort) {
    const sort_value = this.getSortValue(sort);

    switch (sort_method) {
      case 'type':
        cases_view = this.orderCasesByType(cases_view, sort_value);
        break;
      case 'case_id':
        cases_view = this.orderCasesByCaseId(cases_view, sort_value);
        break;

      case 'doctor_name':
        cases_view = this.orderCasesByDoctorName(cases_view, sort_value);
        break;

      case 'shipping_address':
        cases_view = this.orderCasesByShippingAddress(cases_view, sort_value);
        break;

      case 'target_ship_date':
        cases_view = this.orderCasesByTargetShipDate(cases_view, sort_value);
        break;

      case 'shipping_price':
        cases_view = this.ordercasesByShippingPrice(cases_view, sort_value);
        break;

      default:
    }

    return cases_view;
  }
}

ShippingContent.propTypes = {
  all_cases: PropTypes.array.isRequired,
  statuses: PropTypes.object.isRequired,
  mark_shipped_cases: PropTypes.array.isRequired,
  mark_shipped_modal: PropTypes.bool.isRequired,
  mark_shipped_date: PropTypes.string.isRequired,
  ship_date_error: PropTypes.bool.isRequired,
  mark_shipped_error: PropTypes.bool.isRequired,
  mark_shipped_pending: PropTypes.bool.isRequired,
  batch_mark_shipped_error: PropTypes.bool.isRequired,
  batch_all_failed_mark_shipped_error: PropTypes.bool.isRequired,
  batch_labels_modal: PropTypes.bool.isRequired,
  batch_labels_file: PropTypes.string.isRequired,
  refresh: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    all_cases: getAllCases(state),
    statuses: getStatuses(state),
    mark_shipped_cases: getMarkShippedCases(state),
    mark_shipped_modal: getMarkShippedModal(state),
    mark_shipped_date: getSelectedShipDate(state),
    ship_date_error: getShipDateError(state),
    mark_shipped_error: getError(state),
    mark_shipped_pending: getMarkShippedPending(state),
    batch_mark_shipped_error: getBatchMarkShippedError(state),
    batch_all_failed_mark_shipped_error: getBatchAllFailedMarkShippedError(state),
    batch_labels_modal: getBatchLabelsModal(state),
    batch_labels_file: getBatchLabelsFile(state),
    refresh: getRefresh(state),
    only_non_automatic_cases_succeeded: getOnlyNonAutomaticCasesSucceeded(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openBatchShippingMarkShippedModal: openBatchShippingMarkShippedModal,
      closeBatchShippingMarkShippedModal: closeMarkShippedModal,
      onBatchShippingCaseSelect: onBatchShippingCaseSelect,
      onBatchShippingCaseSelectAll: onBatchShippingCaseSelectAll,
      onBatchMarkShippedButtonClick: onBatchMarkShippedButtonClick,
      openBatchLabelsModal: openBatchLabelsModal,
      closeBatchLabelsModal: closeBatchLabelsModal,
      clearMarkShippedCases: clearMarkShippedCases,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShippingContent));
